:root {
  /* colorPrimary */
  --colorPrimary: #00A8FF;
  --colorPrimaryLight: #00A0E9;
  /* white */
  --colorWhite: #FFFFFF;
  --colorBackgroundWhite: #edeffb;
  /* grey */
  --colorGrey: #E5E5E5;
  --colorGreyLight: #F2F2F2;
  --colorBorderGrey: #C8CACE;
  --colorTitleGrey: #8B92A9;
  --colorTipGrey: #6D7278;
  --colorDarkGrey: #949AB0;
  --colorToolTipBorder: #ECECEC;
  /* Text Color */
  --colorTextBlack: #2E3848;
  --colorBlack: #000000;
}