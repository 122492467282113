@import "./style/color.css";
html {
  height: 100%;
}

html, body {
  font-size: 16px;
  position: relative;
}

body {
  margin: 0;
  font-family: PingFangSC-Regular,PingFang SC, Futura, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* https://stackoverflow.com/questions/12046315/prevent-overscrolling-of-web-page */
  overscroll-behavior-y: none;
}

#root {
  box-sizing: border-box;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body input:-internal-autofill-selected {
  background: none!important;
}

.MuiAlert-filledInfo	 {
  background-color: red;
}

.cursor {
  cursor: pointer;
}
