.container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
  box-sizing: border-box;
}

.company {
  color: var(--colorPrimary);
  font-size:0.7rem;
  font-family:ArialMT;
  margin-top: 0.25rem;
  margin-bottom: 2rem;
}

.tip {
  color: var(--colorTipGrey);
  font-size: 0.6rem;
  margin-top: 2rem;
}

.ios {
  width:16.75rem;
  height:8.75rem;
  background:linear-gradient(158deg,rgba(0,163,247,1) 0%,rgba(0,133,255,1) 100%);
  border-radius:0.4rem;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.android {
  width:16.75rem;
  height:8.75rem;
  background:linear-gradient(158deg,rgba(0,224,122,1) 0%,rgba(4,179,109,1) 100%);
  border-radius:0.4rem;
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.platform {
  color: var(--colorWhite);
  font-size:1.2rem;
  font-weight:600;
}

.download {
  font-size:0.8rem;
  color: var(--colorWhite);
  font-weight:600;
}

.notificationList {
  position: fixed;
  margin: auto;
  top: 3.6rem;
  display: flex;
  flex-direction: column;
  z-index: 200;
}

.notification {
  font-size: 0.6rem
}